import React from 'react'

import { Link } from 'gatsby'

import { Helmet } from 'react-helmet'

const Layout = ({ children }) => {
  return (
    <div className='globalStyles'>
      <Helmet>
        <title>PSU Tuba Euphonium</title>
        <meta charSet='utf-8' />
        <meta
          name='Description'
          CONTENT='The Penn State Tuba Euphonium Studio'
        ></meta>
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css?family=Roboto'
        />
      </Helmet>
      <header className='bg-nittanyNavy p-1'>
        <div className='text-white text-center my-8 text-3xl lg:my-12 lg:text-6xl'>
          Penn State Tuba Euphonium
        </div>
      </header>
      <header className='bg-endlessPotential mb-4'>
        <div className='flex justify-center p-3 md:py-4'>
          <span>
            <Link
              className='text-white text-2xs px-1 xs:text-xs xs:px-1.5 md:text-base md:px-5'
              to='/'
            >
              Home
            </Link>
          </span>
          <span>
            <Link
              className='text-white text-2xs px-1 xs:text-xs xs:px-1.5 md:text-base md:px-5'
              to='/faculty'
            >
              Faculty
            </Link>
          </span>
          <span>
            <Link
              className='text-white text-2xs px-1 xs:text-xs xs:px-1.5 md:text-base md:px-5'
              to='/events'
            >
              Events
            </Link>
          </span>
          <span>
            <Link
              className='text-white text-2xs px-1 xs:text-xs xs:px-1.5 md:text-base md:px-5'
              to='/audition'
            >
              Audition
            </Link>
          </span>
          <span>
            <Link
              className='text-white text-2xs px-1 xs:text-xs xs:px-1.5 md:text-base md:px-5'
              to='/degrees'
            >
              Degrees
            </Link>
          </span>
          <span>
            <Link
              className='text-white text-2xs px-1 xs:text-xs xs:px-1.5 md:text-base md:px-5'
              to='/students'
            >
              Students
            </Link>
          </span>
          <span>
            <Link
              className='text-white text-2xs px-1 xs:text-xs xs:px-1.5 md:text-base md:px-5'
              to='/photos'
            >
              Photos
            </Link>
          </span>
        </div>
      </header>
      {children}
    </div>
  )
}

export default Layout
